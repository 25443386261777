import * as React from "react"
import Slider from "react-slick";

import Link from 'components/link';


const LatestNewsHandler = props => {

	const settings = {
		arrows: false,
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 15000,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
	};

	const sectionTitle = 'Latest News';

	let posts = []

	if (props.posts) posts = props.posts;

	if (posts.length === 0) return null;

	return (

		<div className="component-container">
			<div className="top-news-container">
				<div className="grid-container">
					<div className="grid-x grid-padding-x align-left small-align-center">
						<div className="cell small-6 medium-3 text-left small-text-center">
							<div className="">
								<h1 className="latest-news">{sectionTitle}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bottom-container">
				<div className="grid-container">
					<div className="grid-x grid-padding-x show-for-medium">
						{posts.map((post, index) => (
							<div key={`${post.permalink}-${index}`} className="cell medium-4 text-left" >
								<div className="news-card flex-container flex-dir-column align-items">
									<div className="news-photo" style={{ backgroundImage: `url(${post.photo})` }}></div>
									<div className="news-card-content">
										<h2 style={{ alignSelf: 'flex-start' }} className="news-title">{post.title}</h2>
										<p className="news-text">{post.intro}</p>
									</div>
									<Link className="button blue" to={`/blog/${post.permalink}/`}>{(post.cta) ? post.cta : 'Read More'}</Link>
								</div>
							</div>
						))}
					</div>
					<div className="grid-x grid-padding-x hide-for-medium">
						<div className="cell">
							<Slider {...settings}>
								{posts.map((post, index) => (
									<div key={`${post.permalink}-${index}-mobile`} className="slide news-slide">
										<div className="news-card flex-container flex-dir-column align-items">
											<div className="news-photo" style={{ backgroundImage: `url(${post.photo})` }}></div>
											<div className="news-card-content">
												<h2 style={{ alignSelf: 'flex-start' }} className="news-title">{post.title}</h2>
												<p className="news-text">{post.intro}</p>
											</div>
											<Link className="button blue" to={`/blog/${post.permalink}/`}>{(post.cta) ? post.cta : 'Read More'}</Link>
										</div>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}

export default LatestNewsHandler

//const getData = (props) => <DataProvider liveRefresh={true} type="BlogPost" apiParams={{ approved: true, archived: false, deleted: false, _limit: 3, _sort: "showDate,title", _order: "desc" }}><LatestNewsHandler {...props} /></DataProvider>
//export default getData;