
import * as React from "react";

import Link from 'components/link';
import Image from "components/Image";

const SimpleGrid = props => {

	let order = [
		'small-order-1 medium-order-2',
		'small-order-2 medium-order-1'
	]

	return (
		<section className="simple-grid">
			<div className="grid-container">
				<div className="grid-x grid-padding-x align-middle small-align-center">
					<div className={`cell medium-auto small-10 text-left ${!!props.imageFirst ? order[0] : order[1]}`}>
						<div className={`text-card text-content ${props.centered ? "centerText" : ""} ${props.styleContent ? "fancy" : ""}`}>
							{props.styleContent && <hr className="brand" />}
							{props.title && <h3 className="text-title">{props.title}</h3>}
							{(!!props.image && !props.styleContent) && <Image className="show-for-small-only" src={props.image} alt={props.title ? props.title : ''} /> }
							<p className="text-text">{props.body}</p>
							{props.link && <Link className={`button ${props.styleContent ? "" : "blue"}`} to={props.link}>{props.linkText ? props.linkText : "Read More"}</Link>}
						</div>
					</div>
					{!!props.image && <div className={`cell medium-auto ${!props.styleContent ? "hide-for-small-only" : ""} small-10 ${!!props.imageFirst ? order[1] : order[0]}`}>
						<Image src={props.image} alt={props.title ? props.title : ''} />
					</div>}
				</div>
			</div>
		</section>

	)
}

export default SimpleGrid;