import React from 'react';
import { CrowdRiffGallery } from 'react-crowdriff-gallery'

const Crowdriff = (props) => {

	let { hash } = props;

	return (
		<section id="crowdriff">
			<div className="grid-containter">
				<div className="grid-x grid-padding-x">
					<div className="cell small-12 text-center">
						<h2>
							<span>Tag us on Instagram</span>
							#AuthenticWalton
						</h2>
						<div className="instagram-area">
							<CrowdRiffGallery hash={hash} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Crowdriff;