/**
 * Meta component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { Location } from '@reach/router';
import parse from 'node-html-parser';


function isMetaSet(name, meta) {
	for (let i = 0; i < meta.length; i++) {
		if (meta[i].name === name) return true;
		if (meta[i].property === name) return true;
	}
	return false;
}

function deleteMeta(names, meta) {
	if (!Array.isArray(names)) names = names.split(',');
	return meta.filter((item) => names.indexOf(item.name) === -1 && names.indexOf(item.property) === -1);
}


function Meta({ children, description, lang, meta, title, schema, image, location }) {
	const { site } = useStaticQuery(
		graphql`
			 query {
				 site {
					 siteMetadata {
						 title
						 description
						 siteUrl
					 }
				 }
			 }
		 `
	)
	if (typeof meta === 'string') {
		let root;
		/* This try catch looks silly I know, but just trust me.
			When gatsby is built the node-html-parser comes through as an object, which can be destructured but contains a property for the parse function
			But, AFTER gatsby is built and the code is running in the client the module comes through as the default function (parse). So, we try parse() if it works, great, if not, parse.parse(). That's wrapped in a try..catch too because, this is weird enough; what if it breaks again?
		*/
		try {
			root = parse(meta);
		}
		catch (e) {
			try {
				root = parse.parse(meta);
			}
			catch (e) { };
		}
		meta = [];
		if (root) {
			root.querySelectorAll('meta').forEach((node) => {
				meta.push(node.attributes);
			});
		}
	}
	if (!Array.isArray(meta)) meta = [];
	let descriptionSet = false;
	const imgDomain = process.env.IMGIX_DOMAIN;
	meta.forEach((item) => {
		if (item.name === 'description') descriptionSet = true;
		if (item.name === 'image' || item.name === 'og:image' || item.name === 'twitter:image') {
			image = meta[item];
		}
	});
	if (image === undefined) image = `${location.origin || site.siteMetadata.siteUrl}/share-image-2022.jpg`;
	else image = image.replace(`${location.origin || site.siteMetadata.siteUrl}`, imgDomain);
	if (!/https?:\/\//i.test(image)) image = `${imgDomain}${image}`;
	if (!/(\?|&)w=/.test(image)) image += (image.indexOf('?') === -1 ? '?' : '&') + 'w=1200';
	if (schema && schema.image) {
		if (!/https?:\/\//i.test(schema.image)) schema.image = `${imgDomain}${schema.image}`;
		if (!/(\?|&)w=/.test(schema.image)) schema.image += (schema.image.indexOf('?') === -1 ? '?' : '&') + 'w=1200';
	}
	else if (schema) {
		schema.image = image;
	}
	if (image) {
		meta = deleteMeta(['image', 'og:image', 'twitter:image'], meta);
		meta.push({
			name: 'image',
			content: image,
		});
	}
	if (description && descriptionSet === false) {
		meta = deleteMeta(['description', 'og:description', 'twitter:description'], meta);
		descriptionSet = true;
		meta.push({
			name: 'description',
			content: description,
		});
	}
	if (!descriptionSet && site.siteMetadata.description) {
		meta.push({
			name: 'description',
			content: site.siteMetadata.description,
		});
	}
	meta.forEach((item) => {
		if (item.name === 'description') {
			if (!isMetaSet('og:description', meta)) {
				meta.push({
					property: 'og:description',
					content: item.content,
				});
			}
			if (!isMetaSet('twitter:description', meta)) {
				meta.push({
					name: 'twitter:description',
					content: item.content,
				});
			}
		}
		if (item.name === 'image') {
			if (!isMetaSet('og:image', meta)) {
				meta.push({
					property: 'og:image',
					content: item.content,
				})
			}
			if (!isMetaSet('twitter:image', meta)) {
				meta.push({
					name: 'twitter:image',
					content: item.content,
				})
			}
		}
	});
	let titleTemplate = `%s | ${site.siteMetadata.title}`;
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			defaultTitle={site.siteMetadata.title}
			title={title}
			titleTemplate={titleTemplate}
			meta={[
				{
					property: 'og:url',
					content: location.href,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				// {
				// 	name: `twitter:creator`,
				// 	content: site.siteMetadata.author,
				// },
				{
					name: `twitter:title`,
					content: title,
				},
			].concat(meta)}
		> <script key="schema" type="application/ld+json">{JSON.stringify(schema)}</script> {children} </Helmet>
	)
}

Meta.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

Meta.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	// meta: PropTypes.arrayOf(function(propValue, key, componentName, location, propFullName){
	// 	if (propValue typeof !== 'object' && propValue typeof !== 'string')return new Error(`Invalid prop ${propFullName} supplied to ${componentName}. Type must be object or string.`);
	// }),
}


const component = (props) => (
	<Location>
		{locationProps => <Meta location={locationProps.location} {...props}>{props.children}</Meta>}
	</Location>
)

export default component;

 // export default Meta