import * as React from "react";
import Link from "components/link";
const ContentCards = props => {

	const { cards } = props;

	if (!!cards === false || cards.length === 0) return null;

	return (
		<section className={`doublePhotoCard`}>
			<div className="grid-container doublePhotoCard-container">
				<div className="grid-x grid-padding-x align-center ">
					{cards.map((card, index) => {
						return (
							card?.link ? (
								<div key={`${card.title}-${index}`} className="cell small-10 medium-auto photo-card-wrapper">
									<Link to={card.link}>
										<div className="photo-card photo-content" style={{ backgroundImage: `url(${card.image})` }}>
											<h3 className="photo-title">{card.title}</h3>
											<p className="photo-text">{card.body}</p>
										</div>
									</Link>
								</div>
							) : (
								<div key={`${card.title}-${index}`} className="cell small-10 medium-auto" >
									<div className="photo-card photo-content" style={{ backgroundImage: `url(${card.image})` }}>
										<h3 className="photo-title">{card.title}</h3>
										<p className="photo-text">{card.body}</p>
									</div>
								</div>
							)
						)
					})}
				</div>
			</div>
		</section>

	)
}

export default ContentCards;