import * as React from "react";
import Slider from "react-slick";
import Link from "components/link";

const QuickLinks = props => {

	const { quicklinks, featuredLinks, title } = props;
	
	const settings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		autoplay: false,
		autoplaySpeed: 10000,
		slidesToShow: 1,
		slidesToScroll: 1,
		rows: 2,
		slidesPerRow: 1
		// adaptiveHeight: true,
	};

	return (
		<section id="quicklinks-menu">
			<div className="grid-container">
				<div className="grid-x grid-padding-x align-middle align-center">
					<div className="cell small-10 medium-6">
						<div className="links small-text-center">
							<h2>{title}</h2>
							{quicklinks.length > 0 && (
								<ul>
									{quicklinks.map((ql, index) => {
										return (
											<li key={`quicklink-${index}-${ql.linkText}`}><Link to={ql.link}>{ql.linkText}</Link></li>
										)
									})}
								</ul>
							)}
						</div>
					</div>
					<div className="cell small-10 medium-6">
						{featuredLinks.length > 0 && (
							<>
								<div className="featured-links mobile show-for-small-only">
									<Slider {...settings}>
										{featuredLinks.map((fl, index) => {
											return (
												<div key={`featuredlink-mobile-${index}-${fl}`}>
													<div className={`featured-link ${index === 0 ? "first" : ""}`}>
														<img src={fl.icon} alt="" />
														{
															fl.link ?
																<Link to={fl.link ? fl.link : "#"} className={`${fl.link ? "" : "no-link"}`}>
																	{fl.body}
																</Link>
																:
																<>
																	{fl.body}
																</>
														}
													</div>
												</div>
											)
										})}
									</Slider>
								</div>
								<div className="featured-links hide-for-small-only">
									{featuredLinks.map((fl, index) => {
										return (
											<div className={`featured-link`} key={`featuredlink-${index}-${fl}`}>
												<img src={fl.icon} alt="" />
												{
													fl.link ?
														<Link to={fl.link ? fl.link : "#"} className={`${fl.link ? "" : "no-link"}`}>
															{fl.body}
														</Link>
														:
														<>
															{fl.body}
														</>
												}
											</div>
										)
									})}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	)

}

export default QuickLinks;