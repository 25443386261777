import Layout from 'components/templates/Layout';
import ContentCards from "components/templates/global/ContentCards";
import Crowdriff from "components/templates/global/Crowdriff";
import LatestNews from "components/templates/global/LatestNews";
import Meta from "components/templates/global/Meta";
import QuickLinks from 'components/templates/global/QuickLinks';
import SimpleGrid from 'components/templates/global/SimpleGrid';
import { graphql } from 'gatsby';
import truncate from "helpers/truncate";
import zparse from 'helpers/zparse';
import DataProvider from 'providers/data';
import * as React from "react";


const CulinaryExp = props => {
	const { data } = props;

	let category = null
	let page = null;
	let posts = []
	const { edges } = data.allContentPage;
	const { nodes } = data.allSimpleviewCategory;
	let { nodes: listingNodes } = data.allSimpleviewListing;
	const pageNodes = edges.map(({ node }) => node);
	page = pageNodes[0];
	category = nodes[0];

	if ((props.providerResponse.data.length > 0 || !!listingNodes !== false) && props.providerStatusText === 'LOADED') {
		listingNodes = props.providerResponse.data;
	}

	const featuredListing = listingNodes[0];
	const allQuicklinks = listingNodes.slice(1, -2);
	const quicklinks = allQuicklinks.slice(0, -2).map(ql => ({ link: `/listing/${ql.permalink}/`, linkText: ql.companyName }));
	const featuredQuicklinks = allQuicklinks.slice(-2).map(fl => ({ link: `/listing/${fl.permalink}/`, icon: `${fl.photo}?height=181&width=271`, body: truncate(fl.description) }));
	const lastTwoListings = listingNodes.slice(-2).map(listing => ({ title: listing.companyName, body: listing.description, image: listing.photo, link: `/listing/${listing.permalink}/` }));

	if (page?.Post && page.Post.length > 0) {
		posts = page.Post.map(({ object }) => object).slice(0, 3);
	}

	return (
		<Layout headerClass={'no-masthead'} navOnly={true}>
			<>
				<section id="header" className="culi-exp">
					<div className='grid-container'>
						<div className='grid-x grid-padding-x align-center'>
							<div className='cell small-10 medium-12'>
								<div className='header-content'>
									<h1>{category?.name}</h1>
									{category?.longDescription && zparse(category.longDescription)}
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className='simp-grid-wrapper'>
					<SimpleGrid centered={true} title={featuredListing?.companyName} body={featuredListing?.description} image={featuredListing?.photo} link={`/listing/${featuredListing?.permalink}/`} linkText={`View Listing`} imageFirst={true} styleContent={true} />
				</div>
				<QuickLinks title={`Culinary Experiences Quicklinks`} quicklinks={quicklinks} featuredLinks={featuredQuicklinks} />
				<ContentCards cards={lastTwoListings} />
				<LatestNews posts={posts} />
				{page?.crowdriff && <Crowdriff hash={page?.crowdriff} />}
			</>
		</Layout>
	)
}

export function Head(props) {
	const { edges: pageEdges } = props.data.allContentPage;
	const pageNodes = pageEdges.map(({ node }) => node);
	let page = pageNodes[0];

	return (<Meta title={page ? page.pageTitle : ''} meta={page ? page.meta : ''} />)
}

const getData = (props) => <DataProvider liveRefresh={true} type="SimpleviewListing" apiParams={{ _limit: 10, _sort: "randSort", _order: "asc", _join: true, "Category.permalink": "culinary-experiences" }}><CulinaryExp {...props} /></DataProvider>
export default getData;

export const query = graphql`
query CulinaryExp {
	allContentPage(filter: {uri: {eq: "/listings/culinary-experiences/"}}) {
	  edges {
		node {
		  pageTitle
		  Post {
			object {
			  id
			  permalink
			  title
			  photo
			  body
			  uri
			  showDate
			  intro
			}
		  }
		  meta
		}
	  }
	}
	allSimpleviewListing(
	  filter: {Category: {elemMatch: {object: {permalink: {eq: "culinary-experiences"}}}}}
	  limit: 10
	  sort: {fields: randSort, order: ASC}
	) {
		nodes {
		  companyName
		  photo
		  description
		  permalink
		}
	}
	allSimpleviewCategory(
		filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}, photo: {ne: null}, permalink: {eq: "culinary-experiences"}}
		sort: {fields: sort, order: DESC}
	  ) {
		nodes {
		  id
		  name
		  photo
		  description
		  longDescription
		  permalink
		  Listing {
			  object {
				  id
			  }
		  }
		}
	}
}
`